import { AxiosResponse } from "axios";
import { axiosInstance } from "./configURL";
import { RequestDetailsRewardsModel } from "../../models/ListRewardsModel";
interface CampaignData {
  points: number; // adjust this based on your actual data
  // other properties
}
export const homeServices = {
  homeApi: (): Promise<AxiosResponse<CampaignData>> => {
    return axiosInstance.get<CampaignData>("/campaign/home");
  },
  ListRewards: (URL_SPIN: string) => {
    return axiosInstance.post(`/api/get_infor_rewards`, {
      params: {
        url_spin: URL_SPIN,
      },
    });
  },
  DetailsRewards: (id: string) => {
    return axiosInstance.post(`/api/get_details_spin`, {
      params: { reward_id: id },
    });
  },
  DetailsPeriodRewards: (id: string) => {
    return axiosInstance.post(`/api/get_details_periods`, {
      params: { reward_id: id },
    });
  },
  DetailsListRewards: (id: string) => {
    return axiosInstance.post(`/api/get_list_gift_reward`, {
      params: { reward_id: id },
    });
  },
  UpdateRewards: (id: string) => {
    return axiosInstance.post(`/api/validate_code`, {
      params: { reward_id: id },
    });
  },
  UpdateVq2Rewards: (id: number, url_spin: string) => {
    return axiosInstance.post(`/api/get_random_code`, {
      params: { reward_id: id, url_spin: url_spin },
    });
  },
  Login: (username: string, pass: string) => {
    return axiosInstance.post(`/api/login`, {
      params: { login: username, password: pass },
    });
  },
};
