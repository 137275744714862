import "../assets/css/desktop.css";
import "../assets/css/fontText.css";
import LOGO from "../assets/image/logo-blue.png";
import TITLE from "../assets/image/title-2.png";
import BG_REWARD from "../assets/image/bg-reward.png";
import IPHONE from "../assets/image/iphone15.png";
import NHAN from "../assets/image/nhan.png";
import SEEMORE from "../assets/image/seemore.png";
import BTN_ROTATION from "../assets/image/quay2.png";
import { useLocation, useNavigate } from "react-router-dom";
import { Fragment, useEffect, useState } from "react";
import { homeServices } from "../services/apiService/homeService";
import { ListRewardsModel } from "../models/ListRewardsModel";
import { URL_SPIN } from "../services/localService/localService";
import { setAuthorization } from "../services/apiService/configURL";

export default function Home2() {
  const navigation = useNavigate();
  const handleDetails = (id: number) => {
    navigation(`/details-reward/vq2/${id}`);
  };
  const location = useLocation();
  const token = localStorage.getItem("TOKEN");

  const pathUrl = location.pathname.split("/")[1];
  console.log(pathUrl);
  const [lstRewards, setLstRewards] = useState<any>();
  useEffect(() => {
    localStorage.setItem("PATH", "vq2");
    setAuthorization(token as string);
    const url_spin = URL_SPIN + "/" + pathUrl;
    console.log(url_spin);
    homeServices
      .ListRewards(url_spin)
      .then((res: any) => {
        console.log(res);
        setLstRewards(res?.lst_rewards);
      })
      .catch((err) => {
        console.log(err);
      });
  }, []);
  return (
    <div className="bg-vq2 relative">
      {/* <div className="flex"> */}
      <div className="w-full pt-5 relative top-5 left-[10%]">
        <img src={LOGO} />
      </div>
      <div className="w-full flex justify-center absolute top-5 left-0">
        <img src={TITLE} className="w-3/12" />
      </div>
      {/* </div> */}

      {/* <div className="text-[40px] leading-10 font-bold-so text-center text-[#295ea7]">
        ĐƠN HÀNG HỘI NGHỊ THÁNG 9 – NHÓM A<br />
        <span className="text-[30px]">
          TOP 80 ĐƠN HÀNG TỔNG DOANH SỐ CAO NHẤT
        </span>
      </div> */}
      <div className="relative top-14 left-1/2 -translate-x-1/2 text-[32px] leading-10 font-bold-so text-center text-[#F4D85D] border-title bg-[#2076D0] w-7/12 rounded-3xl py-3">
        ĐƠN HÀNG HỘI NGHỊ THÁNG 9 – NHÓM A<br />
        <span className="text-[26px] font-regular-so text-[#ffffff]">
          TOP 80 ĐƠN HÀNG TỔNG DOANH SỐ CAO NHẤT
        </span>
      </div>
      {lstRewards && (
        <div className="grid grid-cols-5 mt-24 px-5">
          <div className="col-span-1 flex justify-center">
            <div className="w-full relative">
              <div className="flex justify-center relative top-1/2 -translate-y-1/2">
                <img
                  src={BG_REWARD}
                  className="w-full 2xl:w-11/12 min-[2000px]:w-10/12"
                />
                <div className="absolute top-2">
                  <div className="relative left-1/2 -translate-x-1/2 w-8/12 sm:w-10/12 2xl:w-9/12 min-[2000px]:w-5/12">
                    <img
                      src={lstRewards[0]?.gift_image}
                      className="w-8/12 relative left-1/2 -translate-x-1/2"
                    />
                    <div className="w-full text-center mt-0 flex flex-col items-center">
                      <div className="font-bold-italic-so text-[24px]">
                        {lstRewards[0]?.name}
                      </div>
                      {lstRewards[0]?.amount_code !== 0 ? (
                        <Fragment>
                          <div className="-mt-1">
                            Có{" "}
                            <span className="font-bold-so text-[30px] text-[#EA2428]">
                              {lstRewards[0]?.amount_code}
                            </span>{" "}
                            giải
                          </div>
                          <button
                            onClick={() => handleDetails(lstRewards[0]?.id)}
                          >
                            <img src={BTN_ROTATION} className="mt-[5%] w-40" />
                          </button>
                        </Fragment>
                      ) : (
                        <div className="grid grid-cols-3 gap-2">
                          {lstRewards[0]?.lst_code?.map((res: any) => (
                            <div className="bg-[#175FAB] text-[#ffffff] px-1 border-giftcode rounded-xl text-[22px] text-center font-bold-so">
                              {res}
                            </div>
                          ))}
                        </div>
                      )}
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
          <div className="col-span-1 flex justify-center">
            <div className="w-full relative">
              <div className="flex justify-center relative top-1/2 -translate-y-1/2">
                <img
                  src={BG_REWARD}
                  className="w-full 2xl:w-11/12 min-[2000px]:w-10/12"
                />
                <div className="absolute top-2">
                  <div className="relative left-1/2 -translate-x-1/2 w-8/12 sm:w-10/12 2xl:w-9/12 min-[2000px]:w-5/12">
                    <img
                      src={lstRewards[1]?.gift_image}
                      className="w-8/12 relative left-1/2 -translate-x-1/2"
                    />
                    <div className="w-full text-center mt-0 flex flex-col items-center">
                      <div className="font-bold-italic-so text-[24px]">
                        {lstRewards[1]?.name}
                      </div>
                      {lstRewards[1]?.amount_code !== 0 ? (
                        <Fragment>
                          <div className="-mt-1">
                            Có{" "}
                            <span className="font-bold-so text-[30px] text-[#EA2428]">
                              {lstRewards[1]?.amount_code}
                            </span>{" "}
                            giải
                          </div>
                          <button
                            className="mt-[5%] w-40"
                            onClick={() => handleDetails(lstRewards[1]?.id)}
                          >
                            <img src={BTN_ROTATION} />
                          </button>
                        </Fragment>
                      ) : (
                        <div className="grid grid-cols-3 gap-2">
                          {lstRewards[1]?.lst_code?.map((res: any) => (
                            <div className="bg-[#175FAB] text-[#ffffff] px-1 border-giftcode rounded-xl text-[22px] text-center font-bold-so">
                              {res}
                            </div>
                          ))}
                        </div>
                      )}
                    </div>
                  </div>
                </div>
              </div>
              {/* {!lstRewards[2]?.check_code_validate && (
                <button
                  className="absolute bottom-0 w-full flex justify-center 2xl:bottom-0 min-[2000px]:bottom-4"
                  onClick={() => handleDetails(lstRewards[2]?.id)}
                >
                  <img src={SEEMORE} className="w-4/12" />
                </button>
              )} */}
            </div>
          </div>
          <div className="col-span-1 flex justify-center">
            <div className="w-full relative">
              <div className="flex justify-center relative top-1/2 -translate-y-1/2">
                <img
                  src={BG_REWARD}
                  className="w-full 2xl:w-11/12 min-[2000px]:w-10/12"
                />
                <div className="absolute top-2">
                  <div className="relative left-1/2 -translate-x-1/2 w-8/12 sm:w-10/12 2xl:w-9/12 min-[2000px]:w-5/12">
                    <img
                      src={lstRewards[2]?.gift_image}
                      className="w-8/12 relative left-1/2 -translate-x-1/2"
                    />
                    <div className="w-full text-center mt-0 flex flex-col items-center">
                      <div className="font-bold-italic-so text-[24px]">
                        {lstRewards[2]?.name}
                      </div>
                      {lstRewards[2]?.amount_code !== 0 ? (
                        <Fragment>
                          <div className="-mt-1">
                            Có{" "}
                            <span className="font-bold-so text-[30px] text-[#EA2428]">
                              {lstRewards[2]?.amount_code}
                            </span>{" "}
                            giải
                          </div>
                          <button
                            className="mt-[5%] w-40"
                            onClick={() => handleDetails(lstRewards[2]?.id)}
                          >
                            <img src={BTN_ROTATION} />
                          </button>
                        </Fragment>
                      ) : (
                        <div className="grid grid-cols-3 gap-2">
                          {lstRewards[2]?.lst_code?.map((res: any) => (
                            <div className="bg-[#175FAB] text-[#ffffff] px-1 border-giftcode rounded-xl text-[22px] text-center font-bold-so">
                              {res}
                            </div>
                          ))}
                        </div>
                      )}
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
          <div className="col-span-1 flex justify-center">
            <div className="w-full relative">
              <div className="flex justify-center relative top-1/2 -translate-y-1/2">
                <img
                  src={BG_REWARD}
                  className="w-full 2xl:w-11/12 min-[2000px]:w-10/12"
                />
                <div className="absolute top-2">
                  <div className="relative left-1/2 -translate-x-1/2 w-8/12 sm:w-10/12 2xl:w-9/12 min-[2000px]:w-5/12">
                    <img
                      src={lstRewards[3]?.gift_image}
                      className="w-8/12 relative left-1/2 -translate-x-1/2"
                    />
                    <div className="w-full text-center mt-0 flex flex-col items-center">
                      <div className="font-bold-italic-so text-[24px]">
                        {lstRewards[3]?.name}
                      </div>
                      {lstRewards[3]?.amount_code !== 0 ? (
                        <Fragment>
                          <div className="-mt-1">
                            Có{" "}
                            <span className="font-bold-so text-[30px] text-[#EA2428]">
                              {lstRewards[3]?.amount_code}
                            </span>{" "}
                            giải
                          </div>
                          <button
                            className="mt-[5%] w-40"
                            onClick={() => handleDetails(lstRewards[3]?.id)}
                          >
                            <img src={BTN_ROTATION} />
                          </button>
                        </Fragment>
                      ) : (
                        <div className="grid grid-cols-3 gap-2">
                          {lstRewards[3]?.lst_code?.map((res: any) => (
                            <div className="bg-[#175FAB] text-[#ffffff] px-1 border-giftcode rounded-xl text-[22px] text-center font-bold-so">
                              {res}
                            </div>
                          ))}
                        </div>
                      )}
                    </div>
                  </div>
                </div>
              </div>
              {/* {!lstRewards[2]?.check_code_validate && (
                <button
                  className="absolute bottom-0 w-full flex justify-center 2xl:bottom-0 min-[2000px]:bottom-4"
                  onClick={() => handleDetails(lstRewards[2]?.id)}
                >
                  <img src={SEEMORE} className="w-4/12" />
                </button>
              )} */}
            </div>
          </div>
          <div className="col-span-1 flex justify-center">
            <div className="w-full relative">
              <div className="flex justify-center relative top-1/2 -translate-y-1/2">
                <img
                  src={BG_REWARD}
                  className="w-full 2xl:w-11/12 min-[2000px]:w-10/12"
                />
                <div className="absolute top-2">
                  <div className="relative left-1/2 -translate-x-1/2 w-8/12 sm:w-10/12 2xl:w-9/12 min-[2000px]:w-5/12">
                    <img
                      src={lstRewards[4]?.gift_image}
                      className="w-8/12 relative left-1/2 -translate-x-1/2"
                    />
                    <div className="w-full text-center mt-0 flex flex-col items-center">
                      <div className="font-bold-italic-so text-[24px]">
                        {lstRewards[4]?.name}
                      </div>
                      {lstRewards[4]?.amount_code !== 0 ? (
                        <Fragment>
                          <div className="-mt-1">
                            Có{" "}
                            <span className="font-bold-so text-[30px] text-[#EA2428]">
                              {lstRewards[4]?.amount_code}
                            </span>{" "}
                            giải
                          </div>
                          <button
                            className="mt-[5%] w-40"
                            onClick={() => handleDetails(lstRewards[4]?.id)}
                          >
                            <img src={BTN_ROTATION} />
                          </button>
                        </Fragment>
                      ) : (
                        <div className="grid grid-cols-3 gap-2">
                          {lstRewards[4]?.lst_code?.map((res: any) => (
                            <div className="bg-[#175FAB] text-[#ffffff] px-1 border-giftcode rounded-xl text-[22px] text-center font-bold-so">
                              {res}
                            </div>
                          ))}
                        </div>
                      )}
                    </div>
                  </div>
                </div>
              </div>
              {/* {!lstRewards[2]?.check_code_validate && (
                <button
                  className="absolute bottom-0 w-full flex justify-center 2xl:bottom-0 min-[2000px]:bottom-4"
                  onClick={() => handleDetails(lstRewards[2]?.id)}
                >
                  <img src={SEEMORE} className="w-4/12" />
                </button>
              )} */}
            </div>
          </div>
        </div>
      )}
    </div>
  );
}
